<!--  -->
<template>
    <div class='Usergroup'>
        <div class="leftcard">
            <div class="flex items-center" style="margin-left: 20px;margin-top: 17px;">
                <img src="https://cdn.fengwork.com/workbench/Group_101.png" alt="" width="22px" height="24px">
                <span style="margin-left: 5px;font-size: 13px;color: #1b1b1b;font-weight: 550;">管理后台</span>
            </div>
            <div style="margin-top: 16px;margin-left: 12px;">
                <div class="leftBtn" @click="switchRouter(2)">流程管理</div>
                <div class="leftBtn activeLeftBtn" @click="switchRouter(1)">用户组管理</div>
            </div>
        </div>
        <div class="middlecard">
            <div class="midTop">
                <div class="inputDiv">
                    <i class="el-icon-search"></i>
                    <input type="text" v-model="searchValue" placeholder="搜索用户群组/用户组">
                </div>
            </div>
            <div class="midmin">
                <div class="minbtn" style="width: 92px;margin-right: 8px;" @click="addUserGroupBtn(1)">新增用户群组</div>
                <div class="minbtn" style="width: 80px;" @click="addUserGroupBtn(2)">新增用户组</div>
                <div style="width: 16px;height: 16px;margin-left: auto;cursor: pointer;" @click="takExport">
                    <img src="@/assets/upload.png" alt="" width="100%" height="100%">
                </div>
            </div>
            <div class="midList">
                <el-tree :data="treeData" ref="tree" node-key="id" :props="defaultProps" default-expand-all
                    :filter-node-method="filterNode">
                    <span class="custom-tree-node" slot-scope="{ node, data }" @click="getUserList(data)">
                        <div style="display: flex;align-items: center;">
                            <img src="@/assets/user.png" alt="" width="15px" height="15px" v-if="node.level == 2">
                            <img src="@/assets/Component.png" alt="" width="15px" height="15px"
                                v-if="node.level == 1 && node.expanded">
                            <img src="@/assets/Component1.png" alt="" width="15px" height="15px"
                                v-if="node.level == 1 && !node.expanded">
                            <span style="margin-left: 6px;">{{ node.label }}</span>
                        </div>
                        <span v-if="data.parentId == -1">
                            <el-button type="text" size="mini" @click.stop="() => editCluster(data)"
                                style="margin-right: 6px;">
                                编辑
                            </el-button>
                        </span>
                    </span>
                </el-tree>
            </div>
        </div>
        <div class="maincard">
            <div class="topL">
                <div style="font-weight: 600;font-size: 14px;color: #1b1b1b;line-height: 14px;margin-right: 8px;">{{
                    groupData.name || '--' }}</div>
                <div @click="systemBtn" v-if="groupData.name"
                    style="width: 40px;height: 20px;line-height:20px;background-color: #f3f4f6;border-radius: 20px;color: #848484;font-size: 12px;cursor: pointer;">
                    设置</div>
            </div>
            <div class="tops">
                <div class="addBtn" style="background-color: #5669ec;color: #fff;" @click="addUser"><i
                        class="el-icon-plus ml-3" style="margin-right: 2px;"></i>添加</div>
                <div class="addBtn" style="background-color: #f3f4f6;color: #848484;" @click="deleteUser"><i
                        class="el-icon-delete ml-3" style="margin-right: 2px;"></i>删除</div>
            </div>
            <div class="bootn">
                <div class="culm">
                    <div style="flex-shrink: 0;width:22px;align-items: center;display: flex;justify-content: end;">
                        <div class="ischeckBox" @click="checkedAll">
                            <img src="@/assets/check2.png" alt="" style="width: 100%;height: 100%;" v-if="isChecked">
                            <img src="@/assets/check1.png" alt="" style="width: 100%;height: 100%;" v-else>
                        </div>
                    </div>
                    <el-row style="flex:1">
                        <el-col class="cumItem" :span="10">名称</el-col>
                        <el-col class="cumItem" :span="11">管理范围</el-col>
                        <el-col class="cumItem" :span="3">操作</el-col>
                    </el-row>
                </div>
                <div class="clist">
                    <div v-for="(item, index) in tableData" :index="item.id">
                        <div style="display: flex;align-items: center;position: relative;">
                            <div
                                style="flex-shrink: 0;width:22px;align-items: center;display: flex;justify-content: end;">
                                <div class="ischeckBox" @click="checkItem(item)">
                                    <img src="@/assets/check4.png" alt="" style="width: 100%;height: 100%;"
                                        v-if="item.check">
                                    <img src="@/assets/check3.png" alt="" style="width: 100%;height: 100%;" v-else>
                                </div>
                            </div>
                            <el-row class="cl">
                                <el-col class="cumItem flex items-center" :span="10">
                                    <div
                                        style="border-radius: 50%;width: 28px;height: 28px;margin:0 8px 0 12px;flex-shrink: 0;overflow: hidden;">
                                        <img :src="item.avatarUrl" alt="" style="width: 100%;height: 100%;"
                                            v-if="item.avatarUrl">
                                        <img src="https://cdn.fengwork.com/workbench/20240118touxiang.png" alt="" v-else
                                            style="width: 100%;height: 100%;">
                                    </div>
                                    <div class="clText"
                                        style="width: calc(100% - 48px);word-break: break-all;text-align: left;">
                                        {{ item.name }}</div>
                                </el-col>
                                <el-col :span="11">
                                    <div class="clText" v-if="item.scope.length"
                                        style="width: 100%;word-break: break-all;">
                                        {{ item.scope.map((e) => e.label).join(",") }}</div>
                                    <div class="clText" v-else>所有人</div>
                                </el-col>
                                <el-col :span="3">
                                    <div style="cursor: pointer;width: 16px;height: 16px;margin: 0 auto;"
                                        @click="setScope(item)"><img src="@/assets/settings.png" alt=""></div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="line"></div>
                    </div>

                </div>
            </div>
        </div>
        <Customdrawer :title="drawerMain.title" ref="customDrawer" @closeDrawer="closeDrawer"
            @submitDrawer="submitDrawer" @deleteDrawer="deleteDrawer">
            <template slot="drawerMain">
                <div class="addGroupDrawer">
                    <div style="text-align: left;font-size: 13px;color: #1b1b1b;font-weight: 500;margin-top: 16px;">
                        {{ drawerMain.drawerMainText }}</div>
                    <div>
                        <div v-if="drawerMain.type == 1 || drawerMain.type == 4">
                            <div class="drawerMainLabel">
                                <div class="cot"></div>用户群组名称
                            </div>
                            <div class="drawerMainInput">
                                <el-input v-model="formLabelAlign.name" placeholder="请输入用户群组名称"
                                    style="width: 100%;"></el-input>
                            </div>
                        </div>
                        <div v-if="drawerMain.type == 2 || drawerMain.type == 5">
                            <div class="drawerMainLabel">
                                <div class="cot"></div>用户组名称
                            </div>
                            <div class="drawerMainInput">
                                <el-input v-model="formLabelAlign.name" placeholder="请输入用户组名称"
                                    style="width: 100%;"></el-input>
                            </div>
                            <div class="drawerMainLabel">
                                <div class="cot"></div>分组到
                            </div>
                            <div class="drawerMainInput">
                                <el-select v-model="formLabelAlign.parentId" placeholder="请选择分组"
                                    :popper-append-to-body="false">
                                    <el-option :label="item.name" :value="item.id" v-for="item in treeData"
                                        :key="item.id"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div v-if="drawerMain.type == 5">
                        <div
                            style="text-align: left;font-size: 13px;color: #1b1b1b;font-weight: 500;margin-top: 23px;border-top: 1px dashed #e4e5e7;padding-top: 24px;">
                            关联流程</div>
                        <div class="dsf" style="display: flex;align-items: center;margin-top: 16px;"
                            v-for="item in flowList" :key="item.id">
                            <div
                                style="width: 28px;height: 28px;border-radius: 50%;overflow: hidden;margin-right: 8px;">
                                <img :src="item.flowUrl" alt="" width="28px" height="28px">
                            </div>
                            <span style="font-size: 13px;color: #1b1b1b;">{{ item.flowName }}</span>
                        </div>

                    </div>


                </div>
            </template>

        </Customdrawer>

        <initiatingRange :title="intitle" ref="initiatingRange" @confirm="getChooseList" :selectedList="chooseList"
            addType="0" :isGroup="isGroup" :required="false" />
    </div>
</template>

<script>
import { findGroupsList, findMembers, addUserGroup, takExport, editUserGroup, deleteGroup, findRelationFlow, setUserScope, addMembers, deleteMembers, findGroupsTree } from '@/api/group'
import Customdrawer from '@/components/customdrawer.vue';
import initiatingRange from "./initiatingRange.vue";
import { uniqBy } from "lodash-es";
import { mapState } from "vuex";

export default {
    name: 'Usergroup',
    components: { Customdrawer, initiatingRange },
    data() {
        return {
            searchValue: '',
            treeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            tableData: [],
            groupData: {},
            drawerMain: {
                drawerMainText: '',
                title: '',
                type: 1,//1:新增用户群组,2:新增用户组,3:,4:修改用户群组,5:修改用户组
            },
            formLabelAlign: {
                name: '',
                parentId: '',
                id: ' '
            },
            flowList: [],


            isGroup: false,
            chooseList: [],
            userId: '',//当前设置管理范围人员id
            groupData: {},
            groupNum: 0,
            isChecked: false,
            intitle: '添加成员'
        };
    },
    //监听属性 类似于data概念
    //监控data中的数据变化
    watch: {
        tableData: {
            deep: true,
            handler(newValue, oldValue) {
                const allActive = newValue.every(item => item.check);
                if (allActive) {
                    this.isChecked = true;
                }
                const allUnActive = newValue.every(item => item.check == false);
                if (allUnActive) {
                    this.isChecked = false;
                }
            },
        },
        searchValue(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.searchFlow();
            }
        }

    },
    filters: {},

    methods: {
        checkedAll() {
            this.isChecked = !this.isChecked;
            this.tableData.forEach(item => {
                item.check = this.isChecked;
            })
        },
        checkItem(item) {
            item.check = !item.check;
        },
        async deleteUser() {
            let members = this.tableData.filter(item => item.check)
            let memberIds = members.map((e) => e.id);
            console.log(`output->memberIds`, memberIds)
            if (memberIds.length == 0) {
                this.$message.success("请选择需要删除的人员");
                return
            }
            const { data } = await deleteMembers(memberIds);
            this.findMembers(this.groupData.id);
            this.multipleSelection = [];
            this.$message.success("删除成功");
        },
        searchFlow() {
            findGroupsTree({ name: this.searchValue }).then(res => {
                this.treeData = res.data
                this.tableData = []
                if (res.data[0]) {
                    if (res.data[0].children && res.data[0].children.length > 0) {
                        console.log(`output->data`,res.data[0].children[0])
                        this.groupData = res.data[0].children[0];
                        this.tableData = res.data[0].children[0].memberList.map(item => ({
                            ...item, 
                            check: false // 新属性
                        }));
                        this.groupNum = res.data[0].children[0].memberList.length;
                    }
                }
            })
        },
        // 树列表搜索
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 获取用户组列表
        getUserList(data) {
            if (data.parentId == -1) return;
            this.findMembers(data.id);
            this.groupData = data;
        },
        async findMembers(groupId) {
            const { data } = await findMembers({ groupId });
            this.tableData = data.map(item => ({
                ...item, // 保留原有属性
                check: false // 新属性
            }));
            // this.tableData = data;
            this.groupNum = data.length;
        },
        async takExport() {
            const res = await takExport();
            // 这里 data 是返回来的二进制数据
            var blob = new Blob([res], {
                type: "application/x-msdownload;charset=UTF-8",
            });
            // 创建一个blob的对象链接
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            // 把获得的blob的对象链接赋值给新创建的这个 a 链接
            // const filename = new Date().getTime()
            link.setAttribute("download", "审批系统--导出用户组.xlsx"); // 设置下载文件名
            document.body.appendChild(link);
            // 使用js点击这个链接
            link.click();
            document.body.removeChild(link) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放blob对象
            this.$message.success("导出成功");
        },

        switchRouter() {
            this.$router.push('/main/workbench/ProcessSetting/backstage')
        },
        findGroupsList() {
            findGroupsTree({ name: this.searchValue }).then(res => {
                this.treeData = res.data
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async findRelationFlow(id) {
            //   const { data } = await findRelationFlow({ groupId: this.ruleForm.id });
            const { data } = await findRelationFlow({
                groupId: id,
            });
            this.flowList = data;
        },
        closeDrawer() {
            this.$refs.customDrawer.drawerShow = false
            this.$refs.customDrawer.type = 1
            this.drawerMain = {
                title: '',
                drawerMainText: '',
                type: 1
            }
            this.formLabelAlign = {
                name: '',
                parentId: '',
                id: ''
            }
        },
        systemBtn() {
            this.findRelationFlow(this.groupData.id)
            this.$refs.customDrawer.drawerShow = true
            this.$refs.customDrawer.type = 5
            this.drawerMain = {
                title: '编辑用户组',
                drawerMainText: '用户组信息',
                type: 5,
            }
            this.formLabelAlign.name = this.groupData.name
            this.formLabelAlign.id = this.groupData.id
            this.formLabelAlign.parentId = this.groupData.parentId

        },
        editCluster(data) {
            this.$refs.customDrawer.drawerShow = true
            this.$refs.customDrawer.type = 4
            this.drawerMain = {
                title: '编辑用户群组',
                drawerMainText: '用户群组信息',
                type: 4,
            }
            this.formLabelAlign.name = data.name
            this.formLabelAlign.id = data.id
        },
        addUserGroupBtn(i) {
            this.$refs.customDrawer.drawerShow = true
            this.$refs.customDrawer.type = i
            if (i == 1) {
                this.drawerMain = {
                    title: '新增用户群组',
                    drawerMainText: '用户群组信息',
                    type: i
                }
            } else if (i == 2) {
                this.drawerMain = {
                    title: '新增用户组',
                    drawerMainText: '用户组信息',
                    type: i
                }
            }

        },
        submitDrawer(type) {
            if (type == 1) {
                if (!this.formLabelAlign.name.trim()) {
                    this.$message.error('请输入用户群组名称')
                    return
                }
                let obj = {
                    id: '',
                    name: this.formLabelAlign.name,
                    level: 1
                }
                addUserGroup(obj).then(res => {
                    if (res.code == 200) {
                        this.$message.success('添加成功')
                        this.closeDrawer()
                        this.findGroupsList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (type == 2) {
                if (!this.formLabelAlign.name.trim()) {
                    this.$message.error('请输入用户组名称')
                    return
                }
                if (!this.formLabelAlign.parentId) {
                    this.$message.error('请选择用户群组')
                    return
                }
                let obj = {
                    id: '',
                    name: this.formLabelAlign.name,
                    level: 2,
                    parentId: this.formLabelAlign.parentId,
                }
                addUserGroup(obj).then(res => {
                    if (res.code == 200) {
                        this.$message.success('添加成功')
                        this.closeDrawer()
                        this.findGroupsList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })

            } else if (type == 3) {

            } else if (type == 4) {
                if (!this.formLabelAlign.name.trim()) {
                    this.$message.error('请输入用户群组名称')
                    return
                }
                let obj = {
                    id: this.formLabelAlign.id,
                    name: this.formLabelAlign.name,
                    level: 1
                }
                editUserGroup(obj).then(res => {
                    if (res.code == 200) {
                        this.$message.success('修改成功')
                        this.closeDrawer()
                        this.findGroupsList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (type == 5) {
                if (!this.formLabelAlign.name.trim()) {
                    this.$message.error('请输入用户组名称')
                    return
                }
                if (!this.formLabelAlign.parentId) {
                    this.$message.error('请选择用户群组')
                    return
                }
                let obj = {
                    id: this.formLabelAlign.id,
                    name: this.formLabelAlign.name,
                    level: 2,
                    parentId: this.formLabelAlign.parentId,
                }
                editUserGroup(obj).then(res => {
                    if (res.code == 200) {
                        this.$message.success('修改成功')
                        this.groupData.name = this.formLabelAlign.name
                        this.groupData.parentId = this.formLabelAlign.parentId
                        this.closeDrawer()
                        this.findGroupsList()

                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }

        },
        deleteDrawer(type) {
            if (type == 4) {
                deleteGroup({ groupId: this.formLabelAlign.id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功')
                        this.closeDrawer()
                        this.findGroupsList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (type == 5) {
                deleteGroup({ groupId: this.formLabelAlign.id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功')
                        this.closeDrawer()
                        this.findGroupsList()
                        this.groupData = ''
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }

        },
        addUser() {
            if (!this.groupData.name) return
            this.isGroup = false;
            this.intitle = "添加成员";
            this.$refs.initiatingRange.dialogVisible = true;

            this.chooseList = this.tableData.map((i) => {
                return {
                    id: i.userId,
                    name: i.name,
                    type: "uid",
                };
            });
        },
        setScope(e) {
            this.isGroup = true;
            this.$refs.initiatingRange.dialogVisible = true;
            this.intitle = "管理范围";
            this.chooseList = e.scope.map((i) => {
                return {
                    id: i.value,
                    name: i.label,
                    type: "dept",
                };
            });
            this.userId = e.id;
        },


        // 设置成员管理范围
        async setUserScope(obj) {
            const { data } = await setUserScope(obj);
            this.findMembers(this.groupData.id);
            this.$message.success("管理范围设置成功");
        },
        // 新增成员
        async addMembers(memberList) {
            const { data } = await addMembers({
                userGroupId: this.groupData.id,
                memberList,
            });
            this.findMembers(this.groupData.id);
            this.$message.success("新增成员成功");
        },
        getChooseList(e) {
            if (this.isGroup) {
                let scope = e.map((i) => {
                    return {
                        value: i.id,
                        label: i.name,
                    };
                });
                let obj = {
                    id: this.userId,
                    scope,
                };
                this.setUserScope(obj);
            } else {
                let fn = (item) => {
                    if (item.children && item.children.length) {
                        item.children.map((i) => {
                            fn(i);
                        });
                    }
                    if (item.userList && item.userList.length) {
                        let deptUser = item.userList.map((i) => {
                            return {
                                avatarUrl: i.avatarUrl,
                                userGroupId: this.groupData.id,
                                userId: i.userId,
                                name: i.userName,
                                scope: [],
                                dept: [
                                    {
                                        label: i.deptName,
                                        value: i.deptId,
                                    },
                                ],
                            };
                        });
                        userArr.push(...deptUser);
                    }
                };
                let userArr = [];
                e.map((i) => {
                    if (i.type == "uid") {
                        userArr.push({
                            avatarUrl: i.avatarUrl,
                            userGroupId: this.groupData.id,
                            userId: i.id,
                            name: i.name,
                            scope: [],
                            dept: [
                                {
                                    label: i.data.deptName,
                                    value: i.data.deptId,
                                },
                            ],
                        });
                    }
                    if (i.type == "dept") {
                        fn(i.data);
                    }
                });
                userArr = uniqBy(userArr, (obj) => obj.userId);

                this.addMembers(userArr);
            }
        },

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.findGroupsList()
        this.$store.dispatch("bpm/listDeptUser");
        this.$store.dispatch("bpm/listSimpleRoles", "");
        this.$store.dispatch("bpm/listSimpleUsers");
        this.$store.dispatch("bpm/listSimpleDepts");
        this.$store.dispatch("bpm/findGroupsList");

    },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    //如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>
<style></style>
<style scoped lang="scss">
.Usergroup {
    padding: 16px;
    height: calc(100% - 32px);
    display: flex;

    .line {
        height: 1px;
        border-top: 1px solid #ececec;
        margin-left: 40px;
        margin-right: 16px;
    }

    .leftcard {
        height: 100%;
        width: 150px;
        border-radius: 6px;
        background: #ffffff;
        margin-right: 8px;

        .leftBtn {
            width: 126px;
            height: 32px;
            border-radius: 4px;
            color: #727272;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            cursor: pointer;
        }

        .leftBtn:hover {
            background: #f3f4f663;

        }

        .activeLeftBtn {
            color: #1b1b1b;
            background: #f3f4f6;
        }
    }

    .midList::-webkit-scrollbar {
        display: none;
    }

    .middlecard {
        height: 100%;
        width: 292px;
        border-radius: 6px;
        background: #ffffff;
        margin-right: 8px;
        padding: 16px;
        padding-bottom: 14px;
        display: flex;
        flex-direction: column;

        .midTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #1b1b1b;
            font-weight: 550;

            .inputDiv {
                width: 100%;
                height: 34px;
                background: #f3f4f6;
                border-radius: 6px;
                display: flex;
                align-items: center;
                color: #848484;
                padding: 10px;
                font-size: 12px;

                input {
                    -web-kit-appearance: none;
                    -moz-appearance: none;
                    outline: 0;
                    border: none;
                    height: 100%;
                    background: #f3f4f6;
                    box-sizing: border-box;
                    font-size: 12px;
                    color: #848484;
                    border-radius: 6px;
                    margin-left: 5px;
                    padding-left: 1px;
                    width: 130px;
                }

            }


        }

        .midmin {
            display: flex;
            align-items: center;
            margin-top: 12px;

            .minbtn {
                height: 28px;
                border-radius: 4px;
                border: 1px solid #f3f4f6;
                box-sizing: border-box;
                background: #ffffff;
                color: #848484;
                font-size: 12px;
                line-height: 28px;
                cursor: pointer;
            }

        }

        .midList {
            margin-top: 12px;
            overflow: auto;
            flex: 1;
        }

        .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            color: #1b1b1b;
        }


    }

    .maincard {
        flex: 1;

        .bootn {
            background: #f3f4f6;
            height: calc(100% - 70px);
            border-radius: 6px 0px 0px 6px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .ischeckBox {
                width: 16px;
                height: 16px;
                margin-top: -3px;
            }

            .culm {
                height: 40px;
                background-color: #5669ec;
                flex-shrink: 0;
                display: flex;

                .cumItem {
                    color: #fafbfc;
                    font-size: 13px;
                    line-height: 40px;
                }


            }

            .clist {
                overflow: auto;

                .cl {
                    height: 59px;
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .clText {
                        font-size: 13px;
                        color: #1b1b1b;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        /*省略几行写几*/
                        -webkit-box-orient: vertical;

                    }

                    .clTextspan {
                        font-size: 13px;
                        color: #1b1b1b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        /*省略几行写几*/
                        -webkit-box-orient: vertical;

                    }
                }
            }
        }

        .topL {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            height: 30px;

        }

        .tops {
            display: flex;
            margin-bottom: 8px;

            .addBtn {
                width: 62px;
                height: 28px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-size: 12px;
                border-radius: 4px;
                margin-right: 8px;
                cursor: pointer;

            }

        }

    }

    .addGroupDrawer {
        .drawerMainLabel {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #848484;
            margin-top: 16px;
            margin-bottom: 12px;

            .cot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #f83e45;
                margin-right: 4px;
            }

        }
    }




}
</style>