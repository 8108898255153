<template>
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane name="field">
        <div slot="label" style="padding:0 8px;">组件属性</div>
      </el-tab-pane>
      <!-- <el-tab-pane name="form">
        <div slot="label" style="padding:0 8px;">表单属性</div>
      </el-tab-pane> -->
    </el-tabs>
    <div class="field-box">
      <el-scrollbar class="right-scrollbar" style="padding: 0 4px 0 8px;">
        <!-- 组件属性 -->
        <el-form :model="activeData" v-show="currentTab === 'field' && showField" size="small" :label-position="'top'"
          ref="dynamicValidateForm" :disabled="activeData.__config__._type_ ? true : false">
          <el-form-item v-if="activeData.__config__.changeTag" label="组件类型">
            <el-select v-model="activeData.__config__.tagIcon" placeholder="请选择组件类型" :style="{ width: '100%' }"
              @change="tagChange" disabled>
              <el-option-group v-for="group in tagList" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.__config__.label" :label="item.__config__.label"
                  :value="item.__config__.tagIcon">
                  <svg-icon class="node-icon" :icon-class="item.__config__.tagIcon" />
                  <span> {{ item.__config__.label }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__vModel__ !== undefined &&
            activeData.__config__.type !== 'litigant'
          " label="字段名">
            <el-input v-model="activeData.__vModel__" placeholder="请输入字段名（v-model）"  disabled/>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.标题 !== undefined" label="组件名">
            {{ activeData.__config__.标题 }}
          </el-form-item>
          <el-form-item v-if="activeData.__config__.label !== undefined &&
            activeData.__config__.type !== 'litigant'
          " label="标题" prop="__config__.label" :rules="[
            { required: true, message: '请输入标题', trigger: 'blur' },
          ]">
            <el-input v-model="activeData.__config__.label" placeholder="请输入标题" @input="changeRenderKey" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.changeContacts" label="指定关联审批" prop="__config__.approval" :rules="[
            {
              required: true,
              message: '请选择指定关联审批',
              trigger: 'change',
            },
          ]">
            <!-- <el-select v-model="activeData.__config__.approval" placeholder="请选择" multiple>
              <el-option v-for="item in activeData.__config__.options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select> -->
            <el-cascader v-model="activeData.__config__.approval" :options="activeData.__config__.options"
              style="width: 100%;" :show-all-levels='false'
              :props="{ label: 'name', value: 'id', multiple: true, emitPath: false }"
              :collapse-tags="activeData.__config__.approval.length > 6 ? true : false"></el-cascader>
          </el-form-item>
          <el-form-item v-if="activeData.placeholder !== undefined" label="占位提示" prop="placeholder" :rules="[
            { required: true, message: '请输入占位提示', trigger: 'blur' },
          ]">
            <el-input v-model="activeData.placeholder" placeholder="请输入占位提示" @input="changeRenderKey" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData['start-placeholder'] !== undefined" label="开始占位" prop="'start-placeholder'"
            :rules="[
              {
                required: true,
                message: '请输入开始占位提示',
                trigger: 'change',
              },
            ]">
            <el-input v-model="activeData['start-placeholder']" placeholder="请输入占位提示" />
          </el-form-item>
          <el-form-item v-if="activeData['end-placeholder'] !== undefined" label="结束占位" prop="'end-placeholder'" :rules="[
            {
              required: true,
              message: '请输入结束占位提示',
              trigger: 'blur',
            },
          ]">
            <el-input v-model="activeData['end-placeholder']" placeholder="请输入占位提示" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.span !== undefined" label="表单栅格">
            <el-slider v-model="activeData.__config__.span" :max="24" :min="1" :marks="{ 12: '' }" @change="spanChange"
              style="width: 90%;" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.layout === 'rowFormItem' &&
            activeData.gutter !== undefined
          " label="栅格间隔">
            <el-input-number v-model="activeData.gutter" :min="0" placeholder="栅格间隔" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.layout === 'rowFormItem' &&
            activeData.type !== undefined
          " label="布局模式">
            <el-radio-group v-model="activeData.type">
              <el-radio-button label="default" />
              <el-radio-button label="flex" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData.justify !== undefined && activeData.type === 'flex'
          " label="水平排列">
            <el-select v-model="activeData.justify" placeholder="请选择水平排列" :style="{ width: '100%' }">
              <el-option v-for="(item, index) in justifyOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.align !== undefined && activeData.type === 'flex'" label="垂直排列">
            <el-radio-group v-model="activeData.align">
              <el-radio-button label="top" />
              <el-radio-button label="middle" />
              <el-radio-button label="bottom" />
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.labelWidth !== undefined" label="标签宽度">
            <el-input v-model.number="activeData.__config__.labelWidth" type="number" placeholder="请输入标签宽度" />
          </el-form-item>
          <el-form-item v-if="activeData.style && activeData.style.width !== undefined" label="组件宽度">
            <el-input v-model="activeData.style.width" placeholder="请输入组件宽度" clearable />
          </el-form-item> -->
          <el-form-item v-if="activeData.__vModel__ !== undefined" label="默认值">
            <el-input :value="setDefaultValue(activeData.__config__.defaultValue)" placeholder="请输入默认值"
              @input="onDefaultValueInput" />
          </el-form-item>
          <!-- <el-form-item
            v-if="activeData.__config__.tag === 'el-checkbox-group'"
            label="至少应选"
          >
            <el-input-number
              :value="activeData.min"
              :min="0"
              placeholder="至少应选"
              @input="$set(activeData, 'min', $event ? $event : undefined)"
            />
          </el-form-item> -->
          <!-- <el-form-item
            v-if="activeData.__config__.tag === 'el-checkbox-group'"
            label="最多可选"
          >
            <el-input-number
              :value="activeData.max"
              :min="0"
              placeholder="最多可选"
              @input="$set(activeData, 'max', $event ? $event : undefined)"
            />
          </el-form-item> -->
          <el-form-item v-if="activeData.__slot__ && activeData.__slot__.prepend !== undefined
          " label="前缀">
            <el-input v-model="activeData.__slot__.prepend" placeholder="请输入前缀" />
          </el-form-item>
          <el-form-item v-if="activeData.__slot__ && activeData.__slot__.append !== undefined
          " label="后缀">
            <el-input v-model="activeData.__slot__.append" placeholder="请输入后缀" />
          </el-form-item>
          <el-form-item v-if="activeData['prefix-icon'] !== undefined" label="前图标" v-show="false">
            <el-input v-model="activeData['prefix-icon']" placeholder="请输入前图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('prefix-icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['suffix-icon'] !== undefined" label="后图标" v-show="false">
            <el-input v-model="activeData['suffix-icon']" placeholder="请输入后图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('suffix-icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['icon'] !== undefined &&
            activeData.__config__.tag === 'el-button'
          " label="按钮图标">
            <el-input v-model="activeData['icon']" placeholder="请输入按钮图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="选项分隔符">
            <el-input v-model="activeData.separator" placeholder="请输入选项分隔符" />
          </el-form-item> -->
          <el-form-item v-if="activeData.autosize !== undefined" label="最小行数">
            <el-input-number v-model="activeData.autosize.minRows" :min="1" placeholder="最小行数" />
          </el-form-item>
          <el-form-item v-if="activeData.autosize !== undefined" label="最大行数">
            <el-input-number v-model="activeData.autosize.maxRows" :min="1" placeholder="最大行数" />
          </el-form-item>
          <el-form-item v-if="isShowMin" label="最小值">
            <el-input-number v-model="activeData.min" placeholder="最小值" />
          </el-form-item>
          <el-form-item v-if="isShowMax" label="最大值" prop="max" :rules="[
            { required: true, message: '请输入最大值', trigger: 'blur' },
          ]">
            <el-input-number v-model="activeData.max" placeholder="最大值" />
          </el-form-item>
          <el-form-item v-if="activeData.height !== undefined" label="组件高度">
            <el-input-number v-model="activeData.height" placeholder="高度" @input="changeRenderKey" />
          </el-form-item>
          <!-- <el-form-item v-if="isShowStep" label="步长">
            <el-input-number v-model="activeData.step" placeholder="步数" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="精度">
            <el-input-number v-model="activeData.precision" :min="0" placeholder="精度" />
          </el-form-item>
            <!-- <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="按钮位置" prop="__config__.tag"
              :rules="[
                { required: true, message: '请输入标题', trigger: 'blur' },
              ]">
              <el-radio-group v-model="activeData['controls-position']">
                <el-radio-button label=""> 默认 </el-radio-button>
                <el-radio-button label="right"> 右侧 </el-radio-button>
              </el-radio-group>
            </el-form-item> -->
          <el-form-item v-if="activeData.maxlength !== undefined" label="最多输入">
            <el-input v-model="activeData.maxlength" placeholder="请输入字符长度">
              <template slot="append"> 个字符 </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['active-text'] !== undefined" label="开启提示" prop="active-text">
            <el-input v-model="activeData['active-text']" placeholder="请输入开启提示" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-text'] !== undefined" label="关闭提示" prop="inactive-text">
            <el-input v-model="activeData['inactive-text']" placeholder="请输入关闭提示" />
          </el-form-item>
          <el-form-item v-if="activeData['active-value'] !== undefined" label="开启值">
            <el-input :value="setDefaultValue(activeData['active-value'])" placeholder="请输入开启值"
              @input="onSwitchValueInput($event, 'active-value')" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-value'] !== undefined" label="关闭值">
            <el-input :value="setDefaultValue(activeData['inactive-value'])" placeholder="请输入关闭值"
              @input="onSwitchValueInput($event, 'inactive-value')" />
          </el-form-item>
          <el-form-item v-if="activeData.type !== undefined &&
            'el-date-picker' === activeData.__config__.tag
          " label="时间类型" required>
            <el-select v-model="activeData.type" placeholder="请选择时间类型" :style="{ width: '100%' }"
              @change="dateTypeChange">
              <el-option v-for="(item, index) in dateOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.name !== undefined" label="文件字段名">
            <el-input v-model="activeData.name" placeholder="请输入上传文件字段名" />
          </el-form-item>
          <el-form-item v-if="activeData.accept !== undefined" label="文件类型" prop="accept" :rules="[
            { required: true, message: '请选择文件类型', trigger: 'change' },
          ]">
            <el-select v-model="activeData.accept" placeholder="请选择文件类型" :style="{ width: '100%' }">
              <!-- <el-option label="视频" value="video/*" />
              <el-option label="音频" value="audio/*" />
              <el-option label="excel" value=".xls,.xlsx" />
              <el-option label="word" value=".doc,.docx" />
              <el-option label="pdf" value=".pdf" /> -->
              <el-option label="图片,mp4,excel,pdf,word" value=".pdf,.xls,.xlsx,.doc,.docx,.mp4,image/*" />
              <el-option label="DWG,pdf,word,excel" value=".pdf,.xls,.xlsx,.doc,.docx,.dwg" />
              <el-option label="图片" value="image/*" />

            </el-select>
            </el-form-item>
            <el-form-item v-if="activeData.__config__.fileSize !== undefined" label="文件大小" prop="__config__.fileSize"
            :rules="[
              { required: true, validator: validateFileSize, trigger: 'blur' }]">
            <el-input v-model="activeData.__config__.fileSize" placeholder="请输入文件大小">
              <template slot="append">MB</template>
            </el-input>
          </el-form-item>
          <!-- <el-form-item v-if="activeData.action !== undefined" label="上传地址">
            <el-input v-model="activeData.action" placeholder="请输入上传地址" clearable />
          </el-form-item> -->
          <!-- <el-form-item v-if="activeData['list-type'] !== undefined" label="列表类型">
            <el-radio-group v-model="activeData['list-type']" size="small">
              <el-radio-button label="text"> text </el-radio-button>
              <el-radio-button label="picture"> picture </el-radio-button>
              <el-radio-button label="picture-card">
                picture-card
              </el-radio-button>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item v-if="activeData.type !== undefined &&
            activeData.__config__.tag === 'el-button'
          " label="按钮类型">
            <el-select v-model="activeData.type" :style="{ width: '100%' }">
              <el-option label="primary" value="primary" />
              <el-option label="success" value="success" />
              <el-option label="warning" value="warning" />
              <el-option label="danger" value="danger" />
              <el-option label="info" value="info" />
              <el-option label="text" value="text" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.buttonText !== undefined"
            v-show="'picture-card' !== activeData['list-type']" label="按钮文字" prop="__config__.buttonText" :rules="[
              { required: true, message: '请输入占位提示', trigger: 'blur' },
            ]">
            <el-input v-model="activeData.__config__.buttonText" placeholder="请输入按钮文字" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-button'" label="按钮文字">
            <el-input v-model="activeData.__slot__.default" placeholder="请输入按钮文字" />
          </el-form-item>
          <el-form-item v-if="activeData['range-separator'] !== undefined" label="分隔符">
            <el-input v-model="activeData['range-separator']" placeholder="请输入分隔符" />
          </el-form-item>
          <el-form-item v-if="activeData['picker-options'] !== undefined" label="时间段" required>
            <el-input v-model="activeData['picker-options'].selectableRange" placeholder="请输入时间段" />
          </el-form-item>
          <el-form-item v-if="activeData.format !== undefined" label="时间格式">
            <el-input :value="activeData.format" placeholder="请输入时间格式" @input="setTimeValue($event)" disabled />
          </el-form-item>
          <!-- 选择型下拉选择 -->
          <template v-if="([
            'el-select',
            'el-select',
            'el-radio-group',
            'el-checkbox-group',
          ].indexOf(activeData.__config__.tag) > -1 &&
            !activeData.__config__.type) || activeData.__config__.type == 'selectOptions'
          ">
            <el-divider>自定义选项</el-divider>
            <!-- <el-form-item v-if="activeData.__config__.dataType" label="数据类型" prop="__config__.dataType" :rules="[
              { required: true, message: '请选择数据类型', trigger: 'blur' },
            ]">
              <el-radio-group v-model="activeData.__config__.dataType" size="small">
                <el-radio-button label="dynamic"> 动态数据 </el-radio-button>
                <el-radio-button label="static"> 静态数据 </el-radio-button>
              </el-radio-group>
            </el-form-item> -->
            <template v-if="activeData.__config__.dataType === 'dynamic'">
              <el-form-item label="接口地址" prop="__config__.url" :rules="[
                {
                  required: true,
                  message: '请输入接口地址',
                  trigger: 'blur',
                },
              ]">
                <el-input v-model="activeData.__config__.url" :title="activeData.__config__.url" placeholder="请输入接口地址"
                  clearable @blur="$emit('fetch-data', activeData)">
                  <el-select slot="prepend" v-model="activeData.__config__.method" :style="{ width: '85px' }"
                    @change="$emit('fetch-data', activeData)">
                    <el-option label="get" value="get" />
                    <el-option label="post" value="post" />
                    <el-option label="put" value="put" />
                    <el-option label="delete" value="delete" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="数据位置">
                <el-input v-model="activeData.__config__.dataPath" placeholder="请输入数据位置"
                  @blur="$emit('fetch-data', activeData)" />
              </el-form-item>

              <template v-if="activeData.props && activeData.props.props">
                <el-form-item label="标签键名">
                  <el-input v-model="activeData.props.props.label" placeholder="请输入标签键名" />
                </el-form-item>
                <el-form-item label="值键名">
                  <el-input v-model="activeData.props.props.value" placeholder="请输入值键名" />
                </el-form-item>
                <!-- <el-form-item label="子级键名">
                  <el-input
                    v-model="activeData.props.props.children"
                    placeholder="请输入子级键名"
                  />
                </el-form-item> -->
              </template>
            </template>
            <template v-if="activeData.__config__.dataType === 'static'">
              <draggable v-if="activeData.__slot__.options.length" :list="activeData.__slot__.options" :animation="340"
                group="selectItem" handle=".option-drag">
                <div v-for="(item, index) in activeData.__slot__.options" :key="index" class="select-item">
                  <div class="select-line-icon option-drag">
                    <i class="el-icon-s-operation" />
                  </div>
                  <!-- <el-input
                    v-model="item.label"
                    placeholder="选项名"
                    size="small"
                  /> -->
                  <el-input placeholder="选项值" v-model="item.label" size="small" :value="item.label"
                    @input="setOptionValue(item, $event)" />
                  <div class="close-btn select-line-icon" @click="activeData.__slot__.options.splice(index, 1)">
                    <i class="el-icon-remove-outline" />
                  </div>
                </div>
              </draggable>
              <div v-else style="
                  width: 100%;
                  text-align: center;
                  line-height: 60px;
                  color: #909399;
                ">
                暂无数据
              </div>
              <div style="display: flex;">
                <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text"
                  @click="addSelectItem">
                  添加选项
                </el-button>
                <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="batchEdit">
                  批量增加
                </el-button>
                <el-button v-if="activeData.isAssociation" style="padding-bottom: 0" type="text"
                  @click="batchAssociation(activeData)">
                  {{ activeData.__slot__.options | ifIsAssociation }}
                </el-button>
              </div>
              <el-button @click="submitTogether(activeData)" size="mini" style="width: 100%;margin-top: 10px;"
                :loading="togetherLoading" v-if="checkList.includes(activeData.__vModel__)" type="primary"
                icon='el-icon-refresh-right'>同步</el-button>
            </template>

            <el-divider />
          </template>
          <template v-if="['el-cascader', 'el-table'].includes(activeData.__config__.tag) &&
            !activeData.__config__.type
          ">
            <el-divider>自定义选项</el-divider>
            <!-- <el-form-item v-if="activeData.__config__.dataType" label="数据类型" prop="__config__.dataType" :rules="[
              { required: true, message: '请选择数据类型', trigger: 'blur' },
            ]">
              <el-radio-group v-model="activeData.__config__.dataType" size="small">
                <el-radio-button label="dynamic"> 动态数据 </el-radio-button>
                <el-radio-button label="static"> 静态数据 </el-radio-button>
              </el-radio-group>
            </el-form-item> -->

            <!-- <template v-if="activeData.__config__.dataType === 'dynamic'">
              <el-form-item label="接口地址" prop="__config__.url" :rules="[
                {
                  required: true,
                  message: '请输入接口地址',
                  trigger: 'blur',
                },
              ]">
                <el-input v-model="activeData.__config__.url" :title="activeData.__config__.url" placeholder="请输入接口地址"
                  clearable @blur="$emit('fetch-data', activeData)">
                  <el-select slot="prepend" v-model="activeData.__config__.method" :style="{ width: '85px' }"
                    @change="$emit('fetch-data', activeData)">
                    <el-option label="get" value="get" />
                    <el-option label="post" value="post" />
                    <el-option label="put" value="put" />
                    <el-option label="delete" value="delete" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="数据位置">
                <el-input v-model="activeData.__config__.dataPath" placeholder="请输入数据位置"
                  @blur="$emit('fetch-data', activeData)" />
              </el-form-item>

              <template v-if="activeData.props && activeData.props.props">
                <el-form-item label="标签键名">
                  <el-input v-model="activeData.props.props.label" placeholder="请输入标签键名" />
                </el-form-item>
                <el-form-item label="值键名">
                  <el-input v-model="activeData.props.props.value" placeholder="请输入值键名" />
                </el-form-item>
                <el-form-item label="子级键名">
                  <el-input v-model="activeData.props.props.children" placeholder="请输入子级键名" />
                </el-form-item>
              </template>
            </template> -->

            <!-- 级联选择静态树 -->
            <el-tree v-if="activeData.__config__.dataType === 'static'" draggable :data="activeData.options"
              node-key="id" :expand-on-click-node="false" :render-content="renderContent" />
            <div v-if="activeData.__config__.dataType === 'static'" style="margin-left: 20px">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addTreeItem">
                添加父级
              </el-button>
            </div>
            <el-divider />
          </template>
          <!-- <el-form-item v-if="activeData.__config__.optionType !== undefined" label="选项样式" required>
            <el-radio-group v-model="activeData.__config__.optionType">
              <el-radio-button label="default"> 默认 </el-radio-button>
              <el-radio-button label="button"> 按钮 </el-radio-button>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item v-if="activeData['active-color'] !== undefined" label="开启颜色">
            <el-color-picker v-model="activeData['active-color']" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-color'] !== undefined" label="关闭颜色">
            <el-color-picker v-model="activeData['inactive-color']" />
          </el-form-item>

          <!-- <el-form-item v-if="activeData.__config__.showLabel !== undefined &&
            activeData.__config__.labelWidth !== undefined
          " label="显示标签">
            <el-switch v-model="activeData.__config__.showLabel" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item> -->
          <el-form-item v-if="activeData.branding !== undefined" label="品牌烙印">
            <el-switch v-model="activeData.branding" @input="changeRenderKey" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData['allow-half'] !== undefined" label="允许半选">
            <el-switch v-model="activeData['allow-half']" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData['show-text'] !== undefined" label="辅助文字">
            <el-switch v-model="activeData['show-text']" @change="rateTextChange" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData['show-score'] !== undefined" label="显示分数">
            <el-switch v-model="activeData['show-score']" @change="rateScoreChange" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData['show-stops'] !== undefined" label="显示间断点">
            <el-switch v-model="activeData['show-stops']" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.range !== undefined" label="范围选择">
            <el-switch v-model="activeData.range" @change="rangeChange" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.border !== undefined &&
            activeData.__config__.optionType === 'default'
          " label="是否带边框">
            <el-switch v-model="activeData.__config__.border" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-color-picker'" label="颜色格式">
            <el-select v-model="activeData['color-format']" placeholder="请选择颜色格式" :style="{ width: '100%' }" clearable
              @change="colorFormatChange">
              <el-option v-for="(item, index) in colorFormatOptions" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.size !== undefined &&
            (activeData.__config__.optionType === 'button' ||
              activeData.__config__.border ||
              activeData.__config__.tag === 'el-color-picker' ||
              activeData.__config__.tag === 'el-button')
          " label="组件尺寸">
            <el-radio-group v-model="activeData.size">
              <el-radio-button label="medium"> 中等 </el-radio-button>
              <el-radio-button label="small"> 较小 </el-radio-button>
              <el-radio-button label="mini"> 迷你 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData['show-word-limit'] !== undefined" label="输入统计">
            <el-switch v-model="activeData['show-word-limit']" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="严格步数">
            <el-switch v-model="activeData['step-strictly']" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader' &&
            activeData.__config__.type !== 'approval' &&
            activeData.__config__.type !== 'litigant'
          " label="任选层级">
            <el-switch v-model="activeData.props.props.checkStrictly" :disabled="activeData.__config__.type == 'contacts' ||
              activeData.__config__.type == 'litigant'" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader' &&
            activeData.__config__.type !== 'litigant'
          " label="是否多选">
            <el-switch v-model="activeData.props.props.multiple" :disabled="activeData.__config__.type == 'litigant'"
              active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="展示全路径" required>
            <el-switch v-model="activeData['show-all-levels']" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="可否筛选">
            <el-switch v-model="activeData.filterable" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.clearable !== undefined" label="能否清空">
            <el-switch v-model="activeData.clearable" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.showTip !== undefined" label="显示提示">
            <el-switch v-model="activeData.__config__.showTip"  active-color="#5669ec" inactive-color="#5669ec1a"/>
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.tag === 'el-upload'" label="多选文件">
            <el-switch v-model="activeData.multiple" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData['auto-upload'] !== undefined" label="自动上传">
            <el-switch v-model="activeData['auto-upload']" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item> -->
          <!-- <el-form-item v-if="activeData.readonly !== undefined" label="是否只读">
            <el-switch v-model="activeData.readonly" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.disabled !== undefined" label="是否禁用">
            <el-switch v-model="activeData.disabled" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-select'" label="能否搜索">
            <el-switch v-model="activeData.filterable" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-select'&&activeData.__config__.type !== 'litigant'" label="是否多选">
            <el-switch v-model="activeData.multiple" @change="multipleChange" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.required !== undefined" label="是否必填">
            <el-switch v-model="activeData.__config__.required" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.total !== undefined && getRowContainer(activeData)" label="统计总数">
            <el-switch v-model="activeData.__config__.total" active-color="#5669ec" inactive-color="#5669ec1a"/>
            <!-- <el-tooltip class="item" effect="dark" content="自动统计金额" placement="top-start">
              <i class="el-icon-info" style="margin-left: 5px;"></i>
            </el-tooltip> -->
          </el-form-item>
          <el-form-item
            v-if="activeData.__config__.selectType == 'radio' || activeData.__config__.selectType == 'checkbox'"
            label="隐藏选项">
            <el-switch v-model="activeData.hideRadio" @change="showRadioChange" active-color="#5669ec"
              inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.type === 'amountMoney'" label="是否大写">
            <el-switch v-model="activeData.__config__.ifBig" />
          </el-form-item>
          <el-form-item
            v-if="activeData.__config__.tagIcon === 'date-range' || activeData.__config__.tagIcon === 'time-range'"
            label="计算时长">
            <el-switch v-model="activeData.__config__.ifRange" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.type === 'amountMoney'" label="计算公式">
            <!-- <el-switch v-model="activeData.__config__.ifBig"  /> -->
            <div @click="editFormula" class="rightBtn">点击编辑</div>
          </el-form-item>
          <template v-if="activeData.__config__.layoutTree" v-slot="{ node, data }">
            <el-form v-if="activeData.__config__.tag === 'el-row'" ref="form" :model="activeData.__config__.form"
              :label-position="'top'">
              <el-form-item label="标题">
                <el-input v-model="activeData.__config__.form.标题"></el-input>
              </el-form-item>

              <el-form-item label="动作名称">
                <el-input v-model="activeData.__config__.form.动作名称"></el-input>
              </el-form-item>

              <el-form-item label="填写方式">
                <el-radio-group v-model="activeData.__config__.form.填写方式">
                  <el-radio-button label="列表"></el-radio-button>
                  <el-radio-button label="表格"></el-radio-button>
                </el-radio-group>

                <img v-show="activeData.__config__.form.填写方式 === '表格'" style="width: 100%; margin-top: 10px"
                  src="@/assets/Group1000004938.png" alt="" />
                <img v-show="activeData.__config__.form.填写方式 === '列表'" style="width: 100%; margin-top: 10px"
                  src="@/assets/Group1000004939.png" alt="" />
              </el-form-item>

              <!-- <el-form-item label="打印格式">
                <el-radio-group v-model="activeData.__config__.form.打印格式">
                  <el-radio-button label="横向打印"></el-radio-button>
                  <el-radio-button label="纵向打印"></el-radio-button>
                </el-radio-group>
              </el-form-item> -->
            </el-form>
          </template>

          <!-- <template v-if="Array.isArray(activeData.__config__.regList)">
            <el-divider>正则校验</el-divider>
            <div v-for="(item, index) in activeData.__config__.regList" :key="index" class="reg-item">
              <span class="close-btn" @click="activeData.__config__.regList.splice(index, 1)">
                <i class="el-icon-close" />
              </span>
              <el-form-item label="表达式">
                <el-input v-model="item.pattern" placeholder="请输入正则"/>
              </el-form-item>
              <el-form-item label="错误提示" style="margin-bottom: 0">
                <el-input v-model="item.message" placeholder="请输入错误提示" />
              </el-form-item>
            </div>
            <div style="margin-left: 20px">
              <el-button icon="el-icon-circle-plus-outline" type="text" @click="addReg">
                添加规则
              </el-button>
            </div>
          </template> -->
        </el-form>
        <!-- 表单属性 -->
        <el-form v-show="currentTab === 'form'" size="small" :label-position="'top'">
          <el-form-item label="表单名">
            <el-input v-model="formConf.formRef" placeholder="请输入表单名（ref）" />
          </el-form-item>
          <el-form-item label="表单模型">
            <el-input v-model="formConf.formModel" placeholder="请输入数据模型" />
          </el-form-item>
          <el-form-item label="校验模型">
            <el-input v-model="formConf.formRules" placeholder="请输入校验模型" />
          </el-form-item>
          <el-form-item label="表单尺寸" v-show=false>
            <el-radio-group v-model="formConf.size">
              <el-radio-button label="medium"> 中等 </el-radio-button>
              <el-radio-button label="small"> 较小 </el-radio-button>
              <el-radio-button label="mini"> 迷你 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签对齐" v-show=false>
            <el-radio-group v-model="formConf.labelPosition">
              <el-radio-button label="left"> 左对齐 </el-radio-button>
              <el-radio-button label="right"> 右对齐 </el-radio-button>
              <el-radio-button label="top"> 顶部对齐 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签宽度" v-show=false>
            <el-input v-model.number="formConf.labelWidth" type="number" placeholder="请输入标签宽度" />
          </el-form-item>
          <el-form-item label="栅格间隔" v-show=false>
            <el-input-number v-model="formConf.gutter" :min="0" placeholder="栅格间隔" />
          </el-form-item>
          <el-form-item label="禁用表单">
            <el-switch v-model="formConf.disabled" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item label="表单按钮">
            <el-switch v-model="formConf.formBtns" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
          <el-form-item label="显示未选中组件边框" v-show=false>
            <el-switch v-model="formConf.unFocusedComponentBorder" active-color="#5669ec" inactive-color="#5669ec1a" />
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>

    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode" />
    <icons-dialog :visible.sync="iconsVisible" :current="activeData[currentIconModel]" @select="setIcon" />

    <Customdialog v-if='batchIncreaseVisible' :dialogWidth="'30%'" :title="'批量编辑'" :dialogHeight="'340px'"
      :isFooter="true" @closeDialog="batchEdit" @submitDialog="batchSubmit">
      <template slot="dialogMain">
        <div style="padding: 16px;">
          <span style="font-size: 12px;">每行对应一个选项</span>
          <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="textarea">
          </el-input>
        </div>
      </template>
    </Customdialog>
    <Customdialog v-if='associationVisible' :dialogWidth="'60%'" :title="'选项关联'" :isFooter="true"
      :dialogHeight="'440px'" @closeDialog="associationEdit" @submitDialog="associationSubmit">
      <template slot="dialogMain">
        <div style="padding: 16px;">
          <span>根据选择的选项，显示其他控件</span>
          <div>
            <el-table :data="activeData.__slot__.options">
              <el-table-column prop="label" label="当前选项" width="300"> </el-table-column>
              <el-table-column prop="lable" label="关联选项">
                <template slot-scope="scope">
                  <el-select style="width: 100%" v-model="scope.row.defaultChecked" multiple placeholder="请选择">
                    <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </Customdialog>
    <Customdialog v-if='calculationVisible' :dialogWidth="'600px'" :title="'计算公式'" :isFooter="true"
      :dialogHeight="'420px'" @closeDialog="calculationCancel" @submitDialog="calculationSubmit">
      <template slot="dialogMain">
        <div style="padding: 16px;">
          <div style="
          border: 1px solid #8f8c8c;
          width: 100%;
          height: 160px;
          margin-bottom: 20px;
          position: relative;
          padding: 10px;
          padding-left: 10px;
        ">
            <el-tag style="margin-right: 16px; margin-bottom: 10px" size="small" :type="item.type == 2 ? 'danger' : ''"
              v-for="(item, index) in FormulaList" :key="index">{{ item.type == 1 ? item.val.__config__.label : item.val
              }}</el-tag>
            <div style="position: absolute; right: 0; bottom: 0; padding-right: 20px">
              <el-button type="text" icon="el-icon-refresh-right" @click="clearFormula">清空</el-button><el-button
                type="text" icon="el-icon-back" style="color: tomato" @click="deleteFormula">删除</el-button>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 90px">计算对象 ：</div>
            <div style="width: 460px">
              <el-tag size="small" v-for="item in filteredArray" :key="item.__config__.formId"
                style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation(item, 1)">
                {{ item.__config__.label }}</el-tag>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 90px">计算符号 ：</div>
            <div style="width: 460px">
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('+', 2)"
                size="small" type="danger">+</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('-', 2)"
                size="small" type="danger">-</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('*', 2)"
                size="small" type="danger">×</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('/', 2)"
                size="small" type="danger">÷</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('(', 2)"
                size="small" type="danger">(</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation(')', 2)"
                size="small" type="danger">)</el-tag>
            </div>
          </div>
          <div style="display: flex">
            <div style="width: 90px">数字键盘 ：</div>
            <div style="width: 460px">
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation(index, 3)"
                size="small" v-for="(item, index) in 10" :key="index">{{ index }}</el-tag>
              <el-tag style="margin-right: 16px; margin-bottom: 10px; cursor: pointer" @click="calculation('.', 3)"
                size="small">.</el-tag>
            </div>
          </div>
        </div>
      </template>
    </Customdialog>
  </div>
</template>

<script>
  import { isArray } from "util";
  import draggable from "vuedraggable";
  import { createForm } from "@/api/flow";
  import TreeNodeDialog from "./TreeNodeDialog";
  import { isNumberStr, saveFormConf } from "@/utils";
  import IconsDialog from "./IconsDialog";
  import {
    inputComponents,
    selectComponents,
    newSelectComponents

  } from "@/components/generator/config";
  import Customdialog from '@/components/customdialog'


  const dateTimeFormat = {
    date: "yyyy-MM-dd",
    week: "yyyy 第 WW 周",
    month: "yyyy-MM",
    year: "yyyy",
    datetime: "yyyy-MM-dd HH:mm:ss",
    daterange: "yyyy-MM-dd",
    monthrange: "yyyy-MM",
    datetimerange: "yyyy-MM-dd HH:mm:ss",
  };

  // 使changeRenderKey在目标组件改变时可用
  const needRerenderList = ["tinymce"];

  export default {
    components: {
      TreeNodeDialog,
      IconsDialog,
      draggable,
      Customdialog
    },
    props: ["showField", "activeData", "formConf", "formData", 'bpmnModeler', 'checkList'],
    data() {

      return {
        FormulaList: [],
        filteredArray: [],
        togetherLoading: false,
        calculationVisible: false,
        textarea: "",
        associationVisible: false, //批量关联显隐
        list: [],
        contactsOptions: "", //指定关联审批
        batchIncreaseVisible: false,
        currentTab: "field",
        currentNode: null,
        dialogVisible: false,
        iconsVisible: false,
        currentIconModel: null,
        form: {
          标题: "",
          动作名称: "",
          填写方式: 0,
          打印格式: 0,
        },
        dateTypeOptions: [
          {
            label: "日(date)",
            value: "date",
          },
          {
            label: "周(week)",
            value: "week",
          },
          {
            label: "月(month)",
            value: "month",
          },
          {
            label: "年(year)",
            value: "year",
          },
          {
            label: "日期时间(datetime)",
            value: "datetime",
          },
        ],
        dateRangeTypeOptions: [
          {
            label: "日期范围(daterange)",
            value: "daterange",
          },
          // {
          //   label: "月范围(monthrange)",
          //   value: "monthrange",
          // },
          {
            label: "日期时间范围(datetimerange)",
            value: "datetimerange",
          },
        ],
        colorFormatOptions: [
          {
            label: "hex",
            value: "hex",
          },
          {
            label: "rgb",
            value: "rgb",
          },
          {
            label: "rgba",
            value: "rgba",
          },
          {
            label: "hsv",
            value: "hsv",
          },
          {
            label: "hsl",
            value: "hsl",
          },
        ],
        justifyOptions: [
          {
            label: "start",
            value: "start",
          },
          {
            label: "end",
            value: "end",
          },
          {
            label: "center",
            value: "center",
          },
          {
            label: "space-around",
            value: "space-around",
          },
          {
            label: "space-between",
            value: "space-between",
          },
        ],
        layoutTreeProps: {
          label(data, node) {
            const config = data.__config__;
            return data.componentName || `${config.label}: ${data.__vModel__}`;
          },
        },
      };
    },
    computed: {
      dateOptions() {
        if (
          this.activeData.type !== undefined &&
          this.activeData.__config__.tag === "el-date-picker"
        ) {
          if (this.activeData["start-placeholder"] === undefined) {
            return this.dateTypeOptions;
          }
          return this.dateRangeTypeOptions;
        }
        return [];
      },
      tagList() {
        return [
          {
            label: "输入型组件",
            options: inputComponents,
          },
          {
            label: "选择型组件",
            options: selectComponents,
          }, {
            label: '增强组件',
            options: newSelectComponents
          }
        ];
      },
      activeTag() {
        return this.activeData.__config__.tag;
      },
      isShowMin() {
        return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
      },
      isShowMax() {
        return (
          ["el-input-number", "el-slider", "el-rate"].indexOf(this.activeTag) > -1
        );
      },
      isShowStep() {
        return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
      },

    },
    watch: {
      formConf: {
        handler(val) {
          saveFormConf(val);
        },
        deep: true,
      },
    },
    filters: {
      ifIsAssociation(options) {
        if (options.length > 0) {
          const allNumsGreaterThanZero = options.some(item => item.defaultChecked?.length > 0)
          if (allNumsGreaterThanZero) {
            return '关联选项(√)'
          } else {
            return '关联选项'
          }
        } else {
          return '关联选项'
        }
      }
    },
    methods: {
      // 判断是否是在行容器中
      getRowContainer(scheme) {
        const rowContainer = this.formData.filter(item => item.__config__.tag === "el-row");
        let data = false;
        rowContainer.forEach(element => {
          const foundItem = element.__config__.children.flat().find(item => item.__vModel__ === scheme.__vModel__);
          if (foundItem) {
            data = element;
          }
        });
        return data;
      },
      validateFileSize(rule, value, callback) {
        // console.log(`output->41234`,rule, value, callback)
        if (!value) {
          return callback(new Error('请填写文件大小'));
        }
        const num = Number(value);
        // 检查是否为数字
        if (isNaN(num)) {
          return callback(new Error('必须是数字'));
        }
        // 检查是否大于300
        if (num > 300) {
          return callback(new Error('不能大于300'));
        }
        callback(); // 验证通过
      },
      //点击选项关联
      batchAssociation(e) {
        let { __vModel__ } = e;
        // console.log(e, "点击选项关联");
        this.list = this.formData.map((item) => {
          if (item.__vModel__) {
            if (__vModel__ === item.__vModel__) {
              return {
                label: item.__config__.label,
                value: item.__vModel__,
                disabled: true,
                defaultChecked: null,
              };
            } else {
              return {
                label: item.__config__.label,
                value: item.__vModel__,
                // disabled:true,
                defaultChecked: null,
              };
            }
          } else {
            console.log(item, "item");
            return {
              label: item.__config__.form.标题,
              value: item.__config__.componentName,
              disabled: false,
              defaultChecked: null,
            };
          }
        });
        this.associationVisible = !this.associationVisible;
      },
      //提交选项关联
      associationSubmit() {
        this.associationVisible = !this.associationVisible;
        this.activeData.isAssociation = true;
      },
      //取消选项关联
      associationEdit() {
        this.associationVisible = !this.associationVisible;
      },
      //提交批量添加
      batchSubmit() {
        this.activeData.__slot__.options = [];
        let arr = this.textarea.split(/[(\r\n)\r\n]+/);
        arr.forEach((e) => {
          this.activeData.__slot__.options.push({
            label: e,
            value: e,
          });
        });
        this.batchIncreaseVisible = !this.batchIncreaseVisible;
        console.log(this.activeData.__slot__.options);
      },
      //编辑批量添加
      batchEdit() {
        let arr = this.activeData.__slot__.options.map((item) => item.label);
        this.textarea = arr.join("\n");
        this.batchIncreaseVisible = !this.batchIncreaseVisible;
      },
      addReg() {
        this.activeData.__config__.regList.push({
          pattern: "",
          message: "",
        });
      },
      addSelectItem() {
        this.activeData.__slot__.options.push({
          label: "",
          value: "",
        });
      },
      addTreeItem() {
        ++this.idGlobal;
        this.dialogVisible = true;
        this.currentNode = this.activeData.options;
      },
      renderContent(h, { node, data, store }) {
        return (
          <div class="custom-tree-node">
            <span>{node.label}</span>
            <span class="node-operation">
              <i
                on-click={() => this.append(data)}
                class="el-icon-plus"
                title="添加"
              ></i>
              <i
                on-click={() => this.remove(node, data)}
                class="el-icon-delete"
                title="删除"
              ></i>
            </span>
          </div>
        );
      },
      append(data) {
        if (!data.children) {
          this.$set(data, "children", []);
        }
        this.dialogVisible = true;
        this.currentNode = data.children;
      },
      remove(node, data) {
        this.activeData.__config__.defaultValue = []; // 避免删除时报错
        const { parent } = node;
        const children = parent.data.children || parent.data;
        const index = children.findIndex((d) => d.id === data.id);
        children.splice(index, 1);
      },
      addNode(data) {
        this.currentNode.push(data);
      },
      setOptionValue(item, val) {
        item.value = isNumberStr(val) ? +val : val;
      },
      setDefaultValue(val) {
        if (Array.isArray(val)) {
          return val.join(",");
        }
        // if (['string', 'number'].indexOf(typeof val) > -1) {
        //   return val
        // }
        if (typeof val === "boolean") {
          return `${val}`;
        }
        return val;
      },
      onDefaultValueInput(str) {
        if (isArray(this.activeData.__config__.defaultValue)) {
          // 数组
          this.$set(
            this.activeData.__config__,
            "defaultValue",
            str.split(",").map((val) => (isNumberStr(val) ? +val : val))
          );
        } else if (["true", "false"].indexOf(str) > -1) {
          // 布尔
          this.$set(this.activeData.__config__, "defaultValue", JSON.parse(str));
        } else {
          // 字符串和数字
          this.$set(
            this.activeData.__config__,
            "defaultValue",
            isNumberStr(str) ? +str : str
          );
        }
      },
      onSwitchValueInput(val, name) {
        if (["true", "false"].indexOf(val) > -1) {
          this.$set(this.activeData, name, JSON.parse(val));
        } else {
          this.$set(this.activeData, name, isNumberStr(val) ? +val : val);
        }
      },
      setTimeValue(val, type) {
        const valueFormat = type === "week" ? dateTimeFormat.date : val;
        this.$set(this.activeData.__config__, "defaultValue", null);
        this.$set(this.activeData, "value-format", valueFormat);
        this.$set(this.activeData, "format", val);
      },
      spanChange(val) {
        this.formConf.span = val;
      },
      multipleChange(val) {
        this.$set(this.activeData.__config__, "defaultValue", val ? [] : "");
      },
      dateTypeChange(val) {
        this.setTimeValue(dateTimeFormat[val], val);
      },
      rangeChange(val) {
        this.$set(
          this.activeData.__config__,
          "defaultValue",
          val ? [this.activeData.min, this.activeData.max] : this.activeData.min
        );
      },
      rateTextChange(val) {
        if (val) this.activeData["show-score"] = false;
      },
      rateScoreChange(val) {
        if (val) this.activeData["show-text"] = false;
      },
      colorFormatChange(val) {
        this.activeData.__config__.defaultValue = null;
        this.activeData["show-alpha"] = val.indexOf("a") > -1;
        this.activeData.__config__.renderKey = +new Date(); // 更新renderKey,重新渲染该组件
      },
      openIconsDialog(model) {
        this.iconsVisible = true;
        this.currentIconModel = model;
      },
      setIcon(val) {
        this.activeData[this.currentIconModel] = val;
      },
      tagChange(tagIcon) {
        let target = inputComponents.find(
          (item) => item.__config__.tagIcon === tagIcon
        );
        if (!target)
          target = selectComponents.find(
            (item) => item.__config__.tagIcon === tagIcon
          );
        this.$emit("tag-change", target);
      },
      contactsChange() {
        createForm().then((res) => {
          this.contactsOptions = res.data;
          this.contactsOptions.length > 0 &&
            (activeData.__config__.approval = this.contactsOptions[0].id);
        });
      },
      changeRenderKey() {
        if (needRerenderList.includes(this.activeData.__config__.tag)) {
          this.activeData.__config__.renderKey = +new Date();
        }
      },
      editFormula() {
        this.filteredArray = this.getNumberComponent();
        this.FormulaList = this.activeData.__config__.formula;
        this.calculationVisible = true;
        // console.log(`output->this.formData`, this.filteredArray);
      },
      // 获取金额组件和计数器组件
      getNumberComponent() {
        let data = [];
        // 递归方法
        const getChildren = elemet => {
          elemet.forEach(item => {
            if (item.__config__.tag === "el-row") {
              getChildren(item.__config__.children[0])
            }
            if (item.__config__.tagIcon == "inumber" || item.__config__.tagIcon == "number" || item.__config__.tagIcon == "amoney") {
              data.push(item)
            }
          })
        }

        getChildren(this.formData)

        return data
      },

      calculationCancel() {
        this.calculationVisible = false;
      },
      calculationSubmit() {
        this.$set(this.activeData.__config__, "formula", this.FormulaList);
        this.calculationVisible = false;
      },
      calculation(val, type) {
        if (type == 1) {
          this.FormulaList.push({ val: val, type: 1 });
        } else if (type == 2) {
          this.FormulaList.push({ val: val, type: 2 });
        } else {
          if (this.FormulaList[this.FormulaList.length - 1]?.type == 3) {
            let oldV = this.FormulaList[this.FormulaList.length - 1].val;
            this.$set(this.FormulaList, this.FormulaList.length - 1, {
              val: oldV + "" + val,
              type: 3,
            });
          } else {
            this.FormulaList.push({ val: val, type: 3 });
          }
        }
        console.log(`output->`, this.FormulaList);
      },
      clearFormula() {
        this.FormulaList = [];
      },
      deleteFormula() {
        this.FormulaList.pop();
      },
      showRadioChange(e) {
        this.$emit('switchRadioChange', e)
      },
      //别问 问就是牛逼！
      async submitTogether(e) {
        this.togetherLoading = true
        let a = this.bpmnModeler.getDefinitions();
        console.log(`output->a`, a)
        let b = a.rootElements[0].flowElements
        const vModel = e.__vModel__
        b.forEach(x => {
          if (x.$type == "bpmn:SequenceFlow" && x.$attrs.conditionList) {
            let c = JSON.parse(x.$attrs.conditionList)
            c.forEach(i => {
              if (i.checkList.includes(vModel)) {
                i.checkConditionList.forEach(j => {
                  if (j.fieldVal == vModel) {
                    // let regex = new RegExp(vModel + "\\s*,\\s*'([^']*)'", "g");
                    // let match;
                    // let replacedStr = x.conditionExpression.body;
                    // // 查找所有匹配项并替换
                    // while ((match = regex.exec(x.conditionExpression.body)) !== null) {
                    //   let value = match[1]; // 提取的值，例如 'a1'
                    //   let index = j.optList.findIndex(item => item.label == value); // 在 j.optList 中查找索引
                    //   if (index !== -1) {
                    //     let replacement = e.__slot__.options[index].label; // 在 arr 中找到对应的值根据index
                    //     replacedStr = replacedStr.replace(new RegExp(vModel + "\\s*,\\s*'" + value + "'", "g"), vModel + ", '" + replacement + "'"); // 替换
                    //   }
                    // }
                    // 动态构建正则表达式，匹配 vModel 后面括号内的内容，允许多个值
                    let regex = new RegExp(`${vModel}\\s*,\\s*'([^']*(?:','[^']*)*)'`, "g");
                    let replacedStr = x.conditionExpression.body;
                    replacedStr = replacedStr.replace(regex, (match, values) => {
                      // 拆分逗号分隔的值
                      let valueArray = values.split("','").map(v => v.replace(/'/g, ""));
                      // 替换每个值
                      let replacedValues = valueArray.map(value => {
                        let index = j.optList.findIndex(item => item.label == value);
                        return index !== -1 ? e.__slot__.options[index]?e.__slot__.options[index].label:value : value;
                      });
                      // 将替换后的值重新拼接
                      return `${vModel}, '${replacedValues.join("','")}'`;
                    });
                    x.conditionExpression.body = replacedStr
                    let indices = j.valList?.map(item => j.optList.findIndex(aItem => aItem.label == item)).filter(index => index !== -1).map(index => e.__slot__.options[index]);
                    let values = indices?.map(item => item?.value)
                    j.valList = values
                    j.optList = e.__slot__.options
                  }
                })
              }
            })
            x.$attrs.conditionList = JSON.stringify(c)
          }
        })
        // console.log(`output->b`, b)
        // a.rootElements[0].flowElements
        this.bpmnModeler._definitions.rootElements[0].flowElements = b
        let { xml } = await this.bpmnModeler.saveXML({ format: true });
        // console.log(`output->xml`,xml)
        this.$emit('changeXml', xml)

        // console.log(`output->this.bpmnModeler`, this.bpmnModeler)

        this.$message.success('同步成功')
        setTimeout(() => {
          this.togetherLoading = false
        }, 1000)


      }
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .right-scrollbar {
    .el-scrollbar__bar {
      display: none;
    }
  }

  .right-board {
    width: 280px;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 3px;

    .field-box {
      position: relative;
      height: calc(100vh - 220px);
      box-sizing: border-box;
      // overflow: hidden;

      // .right-scrollbar {

      //   height: calc(100vh - 220px);


      // }
    }

    // .el-scrollbar {  
    //   height: 100%;
    // }
  }

  .select-item {
    display: flex;
    border: 1px dashed #fff;
    box-sizing: border-box;

    & .close-btn {
      cursor: pointer;
      color: #f56c6c;
    }

    & .el-input+.el-input {
      margin-left: 4px;
    }
  }

  .select-item+.select-item {
    margin-top: 4px;
  }

  .select-item.sortable-chosen {
    border: 1px dashed #409eff;
  }

  .select-line-icon {
    line-height: 32px;
    font-size: 22px;
    padding: 0 4px;
    color: #777;
  }

  .option-drag {
    cursor: move;
  }

  .time-range {
    .el-date-editor {
      width: 227px;
    }

    :deep(.el-icon-time) {
      display: none;
    }
  }



  .node-label {
    font-size: 14px;
  }

  .node-icon {
    color: #bebfc3;
  }

  .rightBtn {
    height: 28px;
    width: 68px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    background-color: #5669ec;
    color: #fff;
  }
</style>